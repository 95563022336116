$background-color: #ecf7f5;

$card-background-color: white;
$card-margin: 20px;

$nav-bar-background-color: white;
$nav-bar-margin: 20px;

$border-radius: 15px;

$title-size: 20px;
$subtitle-size: 18px;
$font-size: 16px;

$primary-color: #60BAAC;
$secondary-color: #858383;

