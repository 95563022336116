// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'variables' as *;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// $md-palette: (
//     50 : #ecf7f5,
//     100 : #d0eae6,
//     200 : #b0ddd6,
//     300 : #90cfc5,
//     400 : #79c4b8,
//     500 : #61baac,
//     600 : #59b3a5,
//     700 : #4fab9b,
//     800 : #45a392,
//     900 : #339482,
//     A100 : #defff9,
//     A200 : #abffef,
//     A400 : #78ffe5,
//     A700 : #5effe0,
//     contrast: (
//         50 : #000000,
//         100 : #000000,
//         200 : #000000,
//         300 : #000000,
//         400 : #000000,
//         500 : #000000,
//         600 : #000000,
//         700 : #000000,
//         800 : #000000,
//         900 : #ffffff,
//         A100 : #000000,
//         A200 : #000000,
//         A400 : #000000,
//         A700 : #000000,
//     )
// );

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $properlake-primary: mat.m2-define-palette($md-palette);
// $properlake-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $properlake-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $properlake-theme: mat.m2-define-light-theme((
//   color: (
//     primary: $properlake-primary,
//     accent: $properlake-accent,
//     warn: $properlake-warn,
//   ),
//   typography: mat.m2-define-typography-config(),
//   density: 0
// ));

// Define a light theme
$properlake-theme: mat.define-theme((color: (theme-type: light,
                primary: mat.$cyan-palette,
            ),
        ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
html {
    @include mat.all-component-themes($properlake-theme);
}


html,
body {
    background-color: $background-color;
}

.card-style {
    margin: $card-margin;
}

mat-button-toggle-group {
    margin-right: 10px;
}

.fullscreen-modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black background with opacity */
}

.fullscreen-modal-content {
    position: relative;
    margin: auto;
    padding: 0;
    width: 100%;
    height: 100%;
}

.container-row {
    display: flex;
    flex-direction: row;
}

.container-column {
    display: flex;
    flex-direction: column;
}

.dialog-content {
    background-color: $card-background-color;
}

mat-label {
    font-size: $font-size;
}

.title {
    font-size: $title-size;
}

.js-plotly-plot .plotly,
.js-plotly-plot .plotly div {
    font-family: Roboto, sans-serif !important;
}

button.mat-mdc-mini-fab {
    background-color: $primary-color !important;
    color: white !important;
    /* Text and icon colours */
}
