/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: Roboto, sans-serif;
}
